import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/table"
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import Button from "../../components/common/Button"
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { Conversation } from '../../types/api_models/interactions'
import { Conversations } from '../../services/api'

interface DashboardMetrics {
    open_conversations: number | null;
    mttr: number | null;
}

interface ConversationsResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: Conversation[];
}

export default function Dashboard() {
    const [metrics, setMetrics] = useState<DashboardMetrics>({ open_conversations: null, mttr: null })
    const [openTickets, setOpenTickets] = useState<Conversation[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        const fetchDashboardData = async () => {
            setIsLoading(true)
            setError(null)
            try {
                const metricsResponse = await Conversations.customEndpoint('metrics/', 'GET')
                setMetrics({
                    open_conversations: metricsResponse.open_conversations ?? null,
                    mttr: metricsResponse.mttr != null ? Math.round(metricsResponse.mttr / 60) : null
                })

                const conversationsResponse = await Conversations.get(currentPage, { status: 'OPEN', page_size: rowsPerPage }) as unknown as ConversationsResponse;
                setOpenTickets(conversationsResponse.results)
                setTotalCount(conversationsResponse.count)
            } catch (err) {
                setError('Failed to fetch dashboard data. Please try again later.')
                console.error('Error fetching dashboard data:', err)
            } finally {
                setIsLoading(false)
            }
        }

        fetchDashboardData()
    }, [currentPage, rowsPerPage])

    const totalPages = Math.ceil(totalCount / rowsPerPage)

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error}</div>
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                <Card className="border-2 border-primary">
                    <CardHeader className="flex flex-row items-center justify-center space-y-0 pb-2 min-h-[40px]">
                        <CardTitle className="text-sm font-medium text-center w-full text-primary">Open Tickets</CardTitle>
                    </CardHeader>
                    <CardContent className="flex items-center justify-center">
                        <div className="text-4xl font-bold">
                            {metrics.open_conversations != null ? metrics.open_conversations : 'N/A'}
                        </div>
                    </CardContent>
                </Card>
                <Card className="border-2 border-primary">
                    <CardHeader className="flex flex-row items-center justify-center space-y-0 pb-2 min-h-[40px]">
                        <CardTitle className="text-sm font-medium text-center w-full text-primary">MTTR (Minutes)</CardTitle>
                    </CardHeader>
                    <CardContent className="flex items-center justify-center">
                        <div className="text-4xl font-bold">
                            {metrics.mttr != null && metrics.mttr > 0 ? metrics.mttr : 'N/A'}
                        </div>
                    </CardContent>
                </Card>
            </div>

            <Card className="border-2 border-primary">
                <CardHeader>
                    <CardTitle className="text-primary">Open Tickets</CardTitle>
                </CardHeader>
                <CardContent>
                    {openTickets.length > 0 ? (
                        <>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>ID</TableHead>
                                        <TableHead>Title</TableHead>
                                        <TableHead>Platform</TableHead>
                                        <TableHead>Started At</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {openTickets.map((ticket) => (
                                        <TableRow key={ticket.id}>
                                            <TableCell>{ticket.id}</TableCell>
                                            <TableCell>{ticket.title}</TableCell>
                                            <TableCell>{ticket.platform}</TableCell>
                                            <TableCell>{new Date(ticket.started_at).toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <div className="flex items-center justify-between mt-4">
                                <Select value={rowsPerPage.toString()} onValueChange={(value) => {
                                    setRowsPerPage(Number(value))
                                    setCurrentPage(1)
                                }}>
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue placeholder="Rows per page" />
                                    </SelectTrigger>
                                    <SelectContent className="bg-white">
                                        <SelectItem value="10">10 rows per page</SelectItem>
                                        <SelectItem value="25">25 rows per page</SelectItem>
                                        <SelectItem value="50">50 rows per page</SelectItem>
                                    </SelectContent>
                                </Select>
                                <div className="flex items-center">
                                    <span className="mr-2">Page {currentPage} of {totalPages}</span>
                                    <Button
                                        variant="secondary"
                                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                        disabled={currentPage === 1}
                                        className="mr-2"
                                    >
                                        <ChevronLeft className="h-4 w-4" />
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                        disabled={currentPage === totalPages}
                                    >
                                        <ChevronRight className="h-4 w-4" />
                                    </Button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="text-center py-4">No Open Tickets</div>
                    )}
                </CardContent>
            </Card>
        </div>
    )
}