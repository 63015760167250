import React from 'react'
import {getIsPartnerView} from '../../utils/auth'
import PartnerDashboard from './PartnerDashboard'
import CompanyDashboard from './CompanyDashboard'

export default function Dashboard() {
    const isPartner = getIsPartnerView();

    if (isPartner) {
        return <PartnerDashboard />
    } else {
        return <CompanyDashboard />
    }
}
