import React, { Suspense, useEffect, useCallback } from 'react'
import { BrowserRouter as Router, useRoutes, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from './contexts/ThemeContext'
import ErrorModal from './components/common/ErrorModal'
import Spinner from './components/common/Spinner'
import { routes } from './routes'
import { checkAuthAndSetup } from './utils/auth'
import { setNavigationFunction } from './utils/navigation';

const queryClient = new QueryClient()

const AppRoutes = () => {
  const element = useRoutes(routes)
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const removeAuthParams = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('login_role');
    newSearchParams.delete('login_company');
    newSearchParams.delete('new_tab');
    setSearchParams(newSearchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    const urlRole = searchParams.get('login_role')
    const urlCompany = searchParams.get('login_company')
    const newTab = searchParams.get('new_tab') === 'true'
    checkAuthAndSetup(navigate, urlRole, urlCompany, location.pathname)
    if (urlRole || urlCompany || newTab) {
      removeAuthParams();
      window.location.reload()
    }
  }, [navigate, location, searchParams, removeAuthParams])

  useEffect(() => {
    setNavigationFunction(navigate);
  }, [navigate]);

  return element
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Router>
          <div className="flex flex-col min-h-screen font-sans bg-white">
            <main className="flex-grow bg-gray-50">
              <Suspense fallback={
                <div className="flex justify-center items-center h-full">
                  <Spinner size="large" color="primary" />
                </div>
              }>
                <AppRoutes />
              </Suspense>
            </main>
          </div>
          <ErrorModal />
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App