import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LayoutDashboard, Shield, FileCheck, Layers, Settings } from 'lucide-react'
import { useSidebar } from '../../contexts/SidebarContext'
import { getIsPartnerView } from "../../utils/auth";

const navItems = [
    { name: 'Dashboard', icon: LayoutDashboard, path: '/dashboard' },
    { name: 'Policies', icon: Shield, path: '/policies' },
    { name: 'Support Tickets', icon: FileCheck, path: '/support-tickets' },
    { name: 'Integrations', icon: Layers, path: '/integrations' },
    { name: 'Settings', icon: Settings, path: '/settings' },
];

export default function Sidebar() {
    const location = useLocation()
    const { isSidebarOpen } = useSidebar()
    const isPartner = getIsPartnerView()

    const filteredNavItems = navItems.filter(item =>
        !(isPartner && (item.name === 'Policies' || item.name === 'Support Tickets'))
    )

    if (!isSidebarOpen) {
        return null
    }

    return (
        <aside className="w-64 bg-white border-r border-primary/20">
            <nav className="divide-y border-y border-primary/20">
                {filteredNavItems.map((item) => (
                    <Link
                        key={item.name}
                        to={item.path}
                        className={`flex items-center px-4 py-3 hover:bg-primary/5 transition-colors ${
                            location.pathname === item.path
                                ? 'font-medium bg-secondary text-secondary-foreground'
                                : 'text-primary'
                        }`}
                    >
                        <item.icon className="h-5 w-5 mr-3" />
                        <span>{item.name}</span>
                    </Link>
                ))}
            </nav>
        </aside>
    )
}