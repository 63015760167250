import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from "../../components/common/Button"
import { Plus } from 'lucide-react'
import PartnerCompanyCard from '../../components/partner/PartnerCompanyCard'
import { PartnerConnections } from '../../services/api'

interface PartnerConnection {
    id: string;
    partner: string;
    company: string;
    role: string;
    company_name: string;
}

export default function PartnerDashboard() {
    const navigate = useNavigate()
    const [partnerCompanies, setPartnerCompanies] = useState<PartnerConnection[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        const fetchPartnerConnections = async () => {
            try {
                const connections = await PartnerConnections.getAll()
                setPartnerCompanies(connections as PartnerConnection[])
            } catch (err) {
                setError('Failed to load partner companies')
                console.error('Error fetching partner connections:', err)
            } finally {
                setIsLoading(false)
            }
        }

        fetchPartnerConnections()
    }, [])

    const handleAddPartner = () => {
        // TODO: Implement add partner functionality
        console.log('Add partner clicked')
    }

    const handleDashboard = (connection: PartnerConnection) => {
        const url = `/dashboard?login_role=${connection.role}&login_company=${connection.company}&new_tab=true`
        window.open(url, '_blank')
    }

    const handleSettings = (connection: PartnerConnection) => {
        console.log(`nav to /partner-company-settings/${connection.id}`)
        navigate(`/partner-company-settings/${connection.id}`)
    }

    if (isLoading) {
        return (
            <div className="container mx-auto px-4 py-8">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold text-primary">Your Partner Companies</h1>
                </div>
                <div className="flex justify-center items-center h-64">
                    <p className="text-xl text-disabled">Loading...</p>
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className="container mx-auto px-4 py-8">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold text-primary">Your Partner Companies</h1>
                </div>
                <div className="flex justify-center items-center h-64">
                    <p className="text-xl text-danger">{error}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold text-primary">Your Partner Companies</h1>
                <Button onClick={handleAddPartner} variant="primary" className="flex items-center gap-2">
                    <Plus className="h-4 w-4" /> Add Partner
                </Button>
            </div>
            {partnerCompanies.length === 0 ? (
                <div className="flex justify-center items-center h-64">
                    <p className="text-xl text-disabled">Add a partner to manage them here</p>
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {partnerCompanies.map((connection) => (
                        <PartnerCompanyCard
                            key={connection.id}
                            companyName={connection.company_name}
                            onDashboardClick={() => handleDashboard(connection)}
                            onSettingsClick={() => handleSettings(connection)}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}
