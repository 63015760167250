import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from '../services/api'
import { AxiosError } from 'axios'
import { Loader2 } from 'lucide-react'

export default function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setIsFormValid(username.trim() !== '' && password.trim() !== '')
  }, [username, password])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!isFormValid) return
    setError('')
    setIsLoading(true)
    try {
      await Auth.login(username, password, (err) => {
        if (err instanceof AxiosError) {
          if (err.response && err.response.status === 400) {
            setError('Username or password is invalid')
          } else {
            setError('An error occurred during login. Please try again.')
          }
        } else {
          setError('An unexpected error occurred. Please try again.')
        }
      })
      navigate('/dashboard')
    } catch (err) {

    } finally {
      setIsLoading(false)
    }
  }

  return (
      <div className="min-h-screen bg-blue-100 flex items-center justify-center p-4">
        <div className="bg-white rounded-2xl shadow-xl max-w-md w-full p-8">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-[#1e4b8f] mb-4">CRADLE</h1>
            <h2 className="text-xl text-[#1e4b8f]">Sign in to your account</h2>
          </div>

          {error && (
              <div className="mb-6 p-4 text-red-700 bg-red-100 rounded-lg text-center">
                {error}
              </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-5">
            <div>
              <label htmlFor="email" className="block text-gray-700 text-base mb-2">
                Email
              </label>
              <input
                  type="email"
                  id="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Email"
                  className="w-full p-3 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-[#1e4b8f]"
                  required
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-gray-700 text-base mb-2">
                Password
              </label>
              <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  className="w-full p-3 border-2 border-blue-200 rounded-lg focus:outline-none focus:border-[#1e4b8f]"
                  required
              />
            </div>

            <button
                type="submit"
                disabled={!isFormValid || isLoading}
                className={`w-full rounded-lg py-3 text-base font-medium transition-colors flex items-center justify-center ${
                    isFormValid && !isLoading
                        ? 'bg-[#1e4b8f] text-white hover:bg-[#163a6f]'
                        : 'bg-disabled text-disabled-foreground cursor-not-allowed'
                }`}
            >
              {isLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Signing In...
                  </>
              ) : (
                  'Sign In'
              )}
            </button>

            <div className="relative my-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-base">
                <span className="px-4 bg-white text-gray-500">or</span>
              </div>
            </div>

            <button
                type="button"
                onClick={() => navigate('/signup')}
                className="w-full bg-[#1e4b8f] text-white rounded-lg py-3 text-base font-medium hover:bg-[#163a6f] transition-colors"
            >
              Sign Up
            </button>
          </form>
        </div>
      </div>
  )
}