import React from 'react'

interface PartnerCompanyCardProps {
    companyName: string;
    onDashboardClick: () => void;
    onSettingsClick: () => void;
}

export default function PartnerCompanyCard({
                                               companyName,
                                               onDashboardClick,
                                               onSettingsClick
                                           }: PartnerCompanyCardProps) {
    return (
        <div className="flex flex-col border rounded-lg overflow-hidden shadow-sm bg-white">
            <div className="p-4 text-center">
                <h3 className="text-xl font-medium text-primary">{companyName}</h3>
            </div>
            <div className="h-px bg-gray-200" />
            <div className="grid grid-cols-2 divide-x">
                <button
                    onClick={onDashboardClick}
                    className="p-3 text-primary hover:bg-gray-50 transition-colors"
                >
                    Dashboard
                </button>
                <button
                    onClick={onSettingsClick}
                    className="p-3 text-primary hover:bg-gray-50 transition-colors"
                >
                    Settings
                </button>
            </div>
        </div>
    )
}