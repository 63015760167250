import React from 'react'

interface ButtonProps {
    children: React.ReactNode
    onClick?: () => void
    className?: string
    type?: 'button' | 'submit' | 'reset'
    variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'ghost'
    disabled?: boolean
}

export default function Button({
                                   children,
                                   onClick,
                                   className = '',
                                   type = 'button',
                                   variant = 'primary',
                                   disabled = false
                               }: ButtonProps) {
    const baseClasses = 'px-4 py-2 rounded transition-colors duration-200'

    const getVariantClass = (variant: string) => {
        switch (variant) {
            case 'primary':
                return 'bg-primary text-primary-foreground hover:bg-primary/90'
            case 'secondary':
                return 'bg-secondary text-secondary-foreground hover:bg-secondary/90'
            case 'danger':
                return 'bg-danger text-danger-foreground hover:bg-danger/90'
            case 'success':
                return 'bg-success text-success-foreground hover:bg-success/90'
            case 'ghost':
                return 'bg-transparent text-primary hover:bg-accent hover:text-accent-foreground'
            default:
                return 'bg-primary text-primary-foreground hover:bg-primary/90'
        }
    }

    const disabledClass = 'opacity-50 cursor-not-allowed bg-disabled text-disabled-foreground'

    return (
        <button
            type={type}
            onClick={onClick}
            className={`
                ${baseClasses} 
                ${disabled ? disabledClass : getVariantClass(variant)} 
                ${className}
            `}
            disabled={disabled}
        >
            {children}
        </button>
    )
}
