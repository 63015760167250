import { useNavigate } from 'react-router-dom';
import { NavigateFunction } from 'react-router-dom';

interface Account {
    role: string;
    company: string;
    company_name: string;
}

interface PartnerAccount {
    role: string;
    company: string;
    partner: string;
    partner_name: string;
    partner_role: string;
}

interface AccountInfo {
    is_partner: boolean;
    accounts: Account[];
    partner_accounts: PartnerAccount[];
}

interface LoginResponse {
    email: string;
    account_info: AccountInfo;
    first_name: string;
    last_name: string;
    access_token: string;
}

export function processLoginResponse(response: LoginResponse): void {
    const { email, account_info, first_name, last_name, access_token } = response;

    // Store the access token
    localStorage.setItem('token', access_token);

    // Store name
    const name = `${first_name} ${last_name}`;
    localStorage.setItem('name', name);

    // Store company and role (first from the list)
    const role = account_info.accounts[0]?.role || '';
    const company = account_info.accounts[0]?.company || '';
    if (company && company.trim() !== '' && role && role.trim() !== '') {
        sessionStorage.setItem('company', company);
        sessionStorage.setItem('role', role);
    }

    // Store partner (first partner from the list) and if present override company
    const partner = account_info.partner_accounts[0]?.partner || '';
    const partnerRole = account_info.partner_accounts[0]?.partner_role || '';
    if (partner && partner.trim() !== '' && partnerRole && partnerRole.trim() !== '') {
        const role = account_info.partner_accounts[0]?.role || '';
        const company = account_info.partner_accounts[0]?.company || '';
        sessionStorage.setItem('partner', partner);
        sessionStorage.setItem('partnerRole', partnerRole);
        sessionStorage.setItem('company', company);
        sessionStorage.setItem('role', role);
    }

    // Store partner status
    localStorage.setItem('isPartner', account_info.is_partner.toString());
    if (account_info.is_partner)
        sessionStorage.setItem('isPartner', account_info.is_partner.toString());

    // Store all accounts
    localStorage.setItem('accounts', JSON.stringify(account_info.accounts));

    // Store partner accounts
    localStorage.setItem('partnerAccounts', JSON.stringify(account_info.partner_accounts));

    // Store user information
    const userInfo = {
        email,
        name,
        role,
        company,
        accounts: account_info.accounts,
        isPartner: account_info.is_partner,
        partnerAccounts: account_info.partner_accounts
    };
    localStorage.setItem('user', JSON.stringify(userInfo));
}

export function clearAuthData(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('partnerRole');
    localStorage.removeItem('isPartner');
    localStorage.removeItem('accounts');
    localStorage.removeItem('partnerAccounts');
    localStorage.removeItem('user');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('company');
    sessionStorage.removeItem('isPartner');
    sessionStorage.removeItem('partner');
    sessionStorage.removeItem('partnerRole');
}

export function isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
}

export function getUser(): Record<string, any> | null {
    const userString = localStorage.getItem('user');
    if (userString) {
        const user = JSON.parse(userString);
        user.role = sessionStorage.getItem('role');
        user.company = sessionStorage.getItem('company');
        return user;
    }
    return null;
}

export function getToken(): string | null {
    return localStorage.getItem('token');
}

export function getName(): string | null {
    return localStorage.getItem('name');
}

export function getRole(): string | null {
    return sessionStorage.getItem('role');
}

export function getCompany(): string | null {
    return sessionStorage.getItem('company');
}

export function getPartner(): string | null {
    return sessionStorage.getItem('partner');
}

export function getPartnerRole(): string | null {
    return sessionStorage.getItem('partnerRole');
}

export function getIsPartnerView(): boolean {
    return sessionStorage.getItem('isPartner') === 'true';
}

export function getAccounts(): Account[] | null {
    const accountsString = localStorage.getItem('accounts');
    return accountsString ? JSON.parse(accountsString) : null;
}

export function getPartnerAccounts(): any[] | null {
    const partnerAccountsString = localStorage.getItem('partnerAccounts');
    return partnerAccountsString ? JSON.parse(partnerAccountsString) : null;
}

export const useLogout = () => {
    const navigate = useNavigate();

    return () => {
        // Clear local storage
        clearAuthData();
        // Redirect to the base URL
        navigate('/login');
    };
};

function setRoleAndCompany(role: string, company: string): boolean {
    sessionStorage.setItem('role', role);
    sessionStorage.setItem('company', company);
    return true;
}

export function setPartner(partner: string, partnerRole: string): void {
    sessionStorage.setItem('partner', partner);
    sessionStorage.setItem('partnerRole', partnerRole);
    sessionStorage.setItem('isPartner', 'true');
}

export function clearPartner(): void {
    sessionStorage.removeItem('partner');
    sessionStorage.removeItem('partnerRole');
    sessionStorage.removeItem('isPartner');
}

export function checkAuthAndSetup(
    navigate: NavigateFunction,
    urlRole: string | null,
    urlCompany: string | null,
    currentPathname: string
): void {
    const token = getToken()

    if (!token) {
        if (currentPathname !== '/login') navigate('/login')
        return
    }
    if (urlRole && urlCompany) {
        clearPartner()
        setRoleAndCompany(urlRole, urlCompany)
    } else {
        let role = getRole()
        let company = getCompany()
        if (!role || !company) {
            const accounts = getAccounts()
            if (accounts && accounts.length > 0) {
                const firstAccount = accounts[0]
                role = firstAccount.role
                company = firstAccount.company
                clearPartner()
                setRoleAndCompany(role, company)
            } else {
                clearAuthData()
                if (currentPathname !== '/login') navigate('/login')
                return
            }
        }
    }

    if (currentPathname === '/login') {
        navigate('/')
    }
}
