import axios, { AxiosError, InternalAxiosRequestConfig, AxiosInstance } from 'axios'
import { config } from '../config'
import { create } from 'zustand'
import {
  clearAuthData,
  getCompany,
  getPartner,
  getPartnerRole,
  getRole,
  getToken,
  processLoginResponse
} from "../utils/auth";
import { navigateToLogin } from "../utils/navigation"

interface ErrorStore {
  isErrorModalOpen: boolean;
  setErrorModalOpen: (isOpen: boolean) => void;
  errorTitle: string;
  setErrorTitle: (title: string) => void;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
}

export const useErrorStore = create<ErrorStore>((set) => ({
  isErrorModalOpen: false,
  setErrorModalOpen: (isOpen) => set({ isErrorModalOpen: isOpen }),
  errorTitle: '',
  setErrorTitle: (title) => set({ errorTitle: title }),
  errorMessage: '',
  setErrorMessage: (message) => set({ errorMessage: message }),
}))

function createAxiosInstance() {
  return axios.create({
    baseURL: config.apiUrl,
    maxRedirects: 5, // Allow up to 5 redirects
  })
}

const api = createAxiosInstance()

const authApi = createAxiosInstance()

const partnerApi = createAxiosInstance()

// Function to set up interceptors
const setupInterceptors = (axiosInstance: AxiosInstance, isAuthApi: boolean, isPartnerApi: boolean) => {
  // Request interceptor
  axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (!isAuthApi) {
      const token = getToken();
      if (token) {
        config.headers.set('Authorization', `Bearer ${token}`)
      }
    }
    if (!isAuthApi && !isPartnerApi) {
      const roleId = getRole();
      if (roleId) {
        config.headers.set('Role', roleId)
      }

      const companyId = getCompany();
      if (companyId) {
        config.headers.set('Company', companyId)
      }
    }
    if (!isAuthApi && isPartnerApi) {
      const partnerId = getPartner();
      if (partnerId) {
        config.headers.set('Company', partnerId)
      }

      const partnerRoleId = getPartnerRole();
      if (partnerRoleId) {
        config.headers.set('Role', partnerRoleId)
      }
    }

    return config
  }, (error) => {
    return Promise.reject(error)
  })

  axiosInstance.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        const errorStore = useErrorStore.getState()
        let errorHandled = false

        if (error.response) {
          if (!isAuthApi && error.response.status === 401) {
            clearAuthData();
            navigateToLogin();
            errorHandled = true
          } else if (error.response.status >= 500 && error.response.status < 600) {
            errorStore.setErrorTitle('Something went wrong')
            errorStore.setErrorMessage('Our engineers are working on resolving this. In the meantime, try refreshing the page.')
            errorStore.setErrorModalOpen(true)
            errorHandled = true
          }
        } else if (error.request) {
          errorStore.setErrorTitle('Cannot connect to our servers')
          errorStore.setErrorMessage('Please check your internet connection')
          errorStore.setErrorModalOpen(true)
          errorHandled = true
        } else {
          errorStore.setErrorTitle('An unexpected error occurred')
          errorStore.setErrorMessage('Please try again later')
          errorStore.setErrorModalOpen(true)
          errorHandled = true
        }

        if (!errorHandled) {
          return Promise.reject(error)
        }
      }
  )
}

// Set up interceptors for api and authApi
setupInterceptors(api, false, false)
setupInterceptors(authApi, true, false)
setupInterceptors(partnerApi, false, true)

class BaseApiService {
  private endpoint: string;
  private apiInstance: AxiosInstance;

  constructor(apiInstance: AxiosInstance, endpoint: string) {
    this.endpoint = endpoint;
    this.apiInstance = apiInstance;
  }

  async create(data: Record<string, any>, queryParams: Record<string, any> = {}, onError?: (error: any) => void): Promise<Record<string, any>> {
    try {
      const response = await this.apiInstance.post(this.endpoint, data, { params: queryParams });
      return response.data;
    } catch (error) {
      if (onError) onError(error);
      throw error;
    }
  }

  async retrieve(id: string, queryParams: Record<string, any> = {}, onError?: (error: any) => void): Promise<Record<string, any>> {
    try {
      const response = await this.apiInstance.get(`${this.endpoint}${id}/`, { params: queryParams });
      return response.data;
    } catch (error) {
      if (onError) onError(error);
      throw error;
    }
  }

  async update(id: string, data: Record<string, any>, queryParams: Record<string, any> = {}, onError?: (error: any) => void): Promise<Record<string, any>> {
    try {
      const response = await this.apiInstance.patch(`${this.endpoint}${id}/`, data, { params: queryParams });
      return response.data;
    } catch (error) {
      if (onError) onError(error);
      throw error;
    }
  }

  async delete(id: string, queryParams: Record<string, any> = {}, onError?: (error: any) => void): Promise<void> {
    try {
      await this.apiInstance.delete(`${this.endpoint}${id}/`, { params: queryParams });
    } catch (error) {
      if (onError) onError(error);
      throw error;
    }
  }

  async get(page: number = 1, queryParams: Record<string, any> = {}, onError?: (error: any) => void): Promise<Record<string, any>[]> {
    try {
      const params = { ...queryParams, page };
      const response = await this.apiInstance.get(this.endpoint, { params });
      return response.data;
    } catch (error) {
      if (onError) onError(error);
      throw error;
    }
  }

  async list(page: number = 1, queryParams: Record<string, any> = {}, onError?: (error: any) => void): Promise<Record<string, any>[]> {
    const response = await this.get(page, queryParams, onError);
    return (response as Record<string, any>)['results'];
  }

  async getAll(queryParams: Record<string, any> = {}, onError?: (error: any) => void): Promise<Record<string, any>[]> {
    try {
      const params = { ...queryParams, page: 1 };
      const response = await this.apiInstance.get(this.endpoint, { params });
      const { count, results } = response.data;
      const pages = Math.floor(count / 50) + (count % 50 === 0 ? 0 : 1);
      if (pages <= 1) return results;

      const allResults = [...results];
      const pageNums = Array.from({ length: pages - 1 }, (_, i) => i + 2);
      const allParams = pageNums.map(pn => ({ ...queryParams, page: pn }));

      // Custom chunk function
      const chunk = (arr: any[], size: number) =>
          Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
              arr.slice(i * size, i * size + size)
          );

      const chunks = chunk(allParams, 5);

      for (let i = 0; i < chunks.length; i++) {
        const chunkResponses = await Promise.all(chunks[i].map(p => this.apiInstance.get(this.endpoint, { params: p })));
        const chunkHasErrors = chunkResponses.some(response => response.status !== 200);
        if (chunkHasErrors) {
          return [];
        }
        chunkResponses.forEach(response => {
          allResults.push(...response.data.results);
        });
      }

      return allResults;
    } catch (error) {
      if (onError) onError(error);
      throw error;
    }
  }

  async customEndpoint(
      path: string,
      method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
      data?: Record<string, any>,
      queryParams: Record<string, any> = {},
      onError?: (error: any) => void
  ): Promise<Record<string, any>> {
    const fullPath = `${this.endpoint}${path}`;
    try {
      let response;
      const config = { params: queryParams };
      switch (method) {
        case 'GET':
          response = await this.apiInstance.get(fullPath, config);
          break;
        case 'POST':
          response = await this.apiInstance.post(fullPath, data, config);
          break;
        case 'PUT':
          response = await this.apiInstance.put(fullPath, data, config);
          break;
        case 'DELETE':
          response = await this.apiInstance.delete(fullPath, config);
          break;
        default:
          throw new Error(`Unsupported HTTP method: ${method}`);
      }
      return response.data;
    } catch (error) {
      if (onError) onError(error);
      throw error;
    }
  }
}

class LoginService {

  async login(username: string, password: string, onError?: (error: any) => void): Promise<Record<string, any>> {
    try {
      const basicToken = btoa(`${config.clientId}:${config.clientSecret}`);
      const response = await authApi.post(`/web-api/oauth/token/`,
          {
            username,
            password,
            grant_type: 'password'
          },
          {
            headers: {
              'Authorization': `Basic ${basicToken}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
      );
      processLoginResponse(response.data);
      return response.data;
    } catch (error) {
      if (onError) onError(error);
      throw error;
    }
  }

  async logout(onError?: (error: any) => void): Promise<void> {
    try {
      await authApi.post(`/web-api/oauth/revoke/`);
    } catch (error) {
      if (onError) onError(error);
      throw error;
    } finally {
      clearAuthData();
    }
  }
}

class ApiService extends BaseApiService {

  constructor(endpoint: string) {
    super(api, endpoint);
  }

}

class PartnerApiService extends BaseApiService {

  constructor(endpoint: string) {
    super(partnerApi, endpoint);
  }

}

export const Auth = new LoginService();

export const Apps = new ApiService('/web-api/integrations/apps/');
export const AppInstalls = new ApiService('/web-api/integrations/app-installs/');
export const AppConnect = new ApiService('/web-api/integrations/connect/');
export const AppSettings = new ApiService('/web-api/integrations/app-settings/');
export const AppConfig = new ApiService('/web-api/integrations/app-config-options/');
export const FirewallPolicy = new ApiService('/web-api/fireballer/policy/');
export const FirewallRules = new ApiService('/web-api/fireballer/firewall-rule/');
export const FirewallPolicyAuthority = new ApiService('/web-api/fireballer/authority/');
export const UrlPolicyRecommend = new ApiService('/web-api/fireballer/url-policy-reco/');
export const Conversations = new ApiService('/web-api/interactions/conversations/');
export const Messages = new ApiService('/web-api/interactions/messages/');
export const Company = new ApiService('/web-api/enterprise/company/');

export const PartnerConnections = new PartnerApiService('/web-api/enterprise/partner-connection/');
export const AppPartnerSettings = new PartnerApiService('/web-api/integrations/app-pc-settings/');
export const Partner = new PartnerApiService('/web-api/enterprise/partner/');
